import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="Hero">
      <div className="HeroContent">
        <div className="Quote">
          <h1 className="quote01">I'm a Product Designer passionate about <span>creating memorable</span></h1>
          <h1 className="quote02">and <span>online experiences</span> and <span>meaningful products</span>.</h1> 
        </div>
      
        <div className="Time">
          <div className="time-text time-parent">
            <div className="time-word">
              <div className="time-letter time-letter-01">贰</div>
              <div className="time-letter time-letter-02">零</div>
              <div className="time-letter time-letter-03">壹</div>
              <div className="time-letter time-letter-04">柒</div>
              <div className="time-letter time-letter-05">-</div>
              <div className="time-letter time-letter-06">贰</div>
              <div className="time-letter time-letter-07">零</div>
              <div className="time-letter time-letter-08">贰</div>
              <div className="time-letter time-letter-09">零</div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="Experience">
        <h2 className="experience01">A product designer 🏂 based in <span>中國上海</span>.</h2>
        <h2 className="experience02">Now at <span><a href="https://www.xiaohongshu.com/" target="_blank">Xiaohongshu</a></span>. Previously at <span><a href="https://www.feishu.cn/en/" target="_blank">Bytedance</a></span> and <span><a href="https://www.smartisan.com/os/" target="_blank">Smartisan</a></span> and <span><a href="https://zi.com/" target="_blank">MoreTech</a></span>.</h2>
      </div>
      
      <div className="Addtion">
        <div className="Circle"></div>
        <p>逍遙無名，摶扶搖而上者九萬里.</p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
